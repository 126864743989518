import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from "styled-components";
import {
	get as _get
} from 'lodash';
import {
  makeStyles,
} from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularIndeterminate from '../../circular-indeterminate';

export const StyledAutocomplete = styled(Autocomplete)`
  ${({ theme }) => `
    .overview {
      &:hover .MuiInputBase-root:not(.Mui-focused) {
        background-color: ${theme.palette.grey[200]}
      }

      & .MuiOutlinedInput-root {
        &:not(.Mui-focused) fieldset {
          &.MuiOutlinedInput-notchedOutline {
            border-width: 0;
          }
        }
      }
      & .MuiInput-underline {
        &:not(.Mui-focused):before {
          border-width: 0;
        }
      }
      &.status .MuiInputBase-root:not(.Mui-focused) {
        margin-top: -5px;
        padding-right: 8px;
        background: #E0E0E0;

        & .MuiInputBase-input.MuiAutocomplete-input {
          font-size: 16px;
          text-align: center;
        }
        & .MuiAutocomplete-endAdornment {
          display: none;
        }
      }

      &.status .MuiFormLabel-root.MuiInputLabel-root:not(.Mui-focused) {
        display: none;
      }

      .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(12px, -6px) scale(0.75);
      }
    }
    .MuiAutocomplete-endAdornment {
      top: calc(50% - 10px);
    }
    .MuiAutocomplete-popupIndicator {
      margin-top: -6px;
    },
    .MuiAutocomplete-clearIndicator {
      margin-top: -6px;
    }
  `}
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    position: 'relative',
    width: '100%'
	},
	helperText: {
		padding: '0 10px',
	},
}));

const AtAutocomplete = (props: any) => {
  const {
    className, // For Form Control
    errors,
    optionLabelKey,
    optionValueKey,
    name,
    value,
    groupBy,
    options,
    loading,
    isCreatable,
    AutocompleteProps,
    InputProps,
  } = props;

  const classes = useStyles();
  const error = _get(errors, [name || '']);
  const [ newOption, setNewOption ] = useState(null as any);
  const [ selectedOption, setSelectedOption ] = useState(null as any);
  const [ selectedOptions, setSelectedOptions ] = useState([] as any);

  useEffect(() => {
    if (!value) {
      if (!AutocompleteProps.multiple) {
        setSelectedOption(null);
      } else {
        setSelectedOptions([]);
      }
    } else {
      if (!AutocompleteProps.multiple) {
        const temp = options.find((o: any) => typeof value === 'object' ? o[optionValueKey] === value[optionValueKey] : o[optionValueKey] === value ) || null;
        setSelectedOption(temp);
      } else {
        const temp = options.filter((o: any) => {
          if (!o)
            return false;
          const temp = (value || []).find((v: any) => {
            if (!v)
              return false;
            return typeof v === 'object' ? o[optionValueKey] === v[optionValueKey] : o[optionValueKey] === v;
          });
          return !!temp;
        });
        setSelectedOptions(temp);
      }
    }
    return () => {}
  }, [
    value,
    options
  ]);

  const renderAutocomplete = useMemo(() => {
    return (
      <StyledAutocomplete
        fullWidth
        value={AutocompleteProps.multiple ? selectedOptions : selectedOption}
        groupBy={groupBy || null}
        options={newOption ? [newOption, ...options] : (options || [])}
        onInputChange={(event: object, value: string, reason: string) => {
          if (!isCreatable || reason !== 'input')
            return;
          if (!value)
            setNewOption(null);
          else
            setNewOption({[`${optionLabelKey}`]: `Create ${value}`, [`${optionValueKey}`]: value, isNew: true});
        }}
        onClose={(event: object, reason: string) => {
          if (isCreatable && !!newOption)
            setNewOption(null);
        }}
        {
          ...AutocompleteProps
        }
        renderInput={(params: any) => {
          return (
            <TextField
              {
                ...params
              }
              {
                ...InputProps
              }
            />
          )
        }}
      />
    );
  }, [
    newOption,
    selectedOption,
    selectedOptions,
    options,
    isCreatable,
    AutocompleteProps,
    InputProps,
  ])

  return (
		<FormControl
			className={clsx(classes.formControl, className)}
			error={error ? true : false}
    >
      { renderAutocomplete }
			{
				error ? (
					<FormHelperText className={classes.helperText}>
						<b>{error.message}</b>
					</FormHelperText>
				) : null
			}
      {
        loading && (
          <CircularIndeterminate size={16}/>
        )
      }
		</FormControl>
  );
}

AtAutocomplete.defaultProps = {
  InputLabelProps: {},
  SelectProps: {},
  errors: {},
};

export default AtAutocomplete;
