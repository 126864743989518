import {
  get as _get,
  filter as _filter,
  find as _find,
  findIndex as _findIndex,
  isNull,
  toInteger,
} from 'lodash'

import { IFacilitiesState } from './facilities-redux.model'
import { FacilityActionType } from './facilities-action-types'
// import { formatDate } from '@telerik/kendo-intl';
import { format } from 'date-fns';
import { ATadlTasks } from '../../settings/app';

export const initialFacilitiesState: IFacilitiesState = {
  hospitals: {
    data: [],
    total: 0,
  },
  clinics: {
    data: [],
    total: 0,
  },
  therapists: {
    data: [],
    total: 0,
  },
  patients: {
    data: [],
    total: 0,
  },
  sessions: {
    data: [],
    total: 0,
  },
  notes: {
    data: [],
    total: 0,
  },
  userReportUsage: [],
  activeFacility: null,
  activeClinic: null,
  activeTherapist: null,
  activePatient: null,
  activeSession: null,
  loading: false,
  loadingUserReportUsage: false,
  postLoading: false,
  facilityError: null,
}

const convertToDateObject = (dateString: string, timeString: string) => {
  var returnObj = new Date(dateString + " " + timeString);
  // var timeObj = new Date(timeString);
  // var hrs = timeObj.getHours();
  // var mins = timeObj.getMinutes();
  // var secs = timeObj.getSeconds();
  // returnObj.setHours(hrs);
  // returnObj.setMinutes(mins);
  // returnObj.setSeconds(secs);
  return returnObj;
}
const facilitiesReducer = (
  state: IFacilitiesState = initialFacilitiesState,
  action: any
) => {
  let type: string
  let temp: any
  let tempData: any
  let tempSourceData: any

  switch (action.type) {
    case FacilityActionType.GetFacilities:
      type = _get(action, ['payload', 'type']) || 'hospitals'
      tempData = { data: [], total: 0 }

      if (['activeTherapist', 'activePatient'].indexOf(type) >= 0) {
        tempData = null
      }

      return {
        ...state,
        loading: true,
        facilityError: null,
        [`${type}`]: tempData,
      }

    case FacilityActionType.GetFacilitiesSuccess:
      type = _get(action, ['payload', 'type']) || 'hospitals'
      tempData = _get(action, ['payload', 'data', 'data']) || {
        data: [],
        total: 0,
      }

      if (['activeTherapist', 'activePatient'].indexOf(type) < 0) {
        for (let i = 0; i < tempData.data.length; i++) {
          if (!!tempData.data[i].date) {
            tempData.data[i].date = new Date(tempData.data[i].date)
          }

          if (!!tempData.data[i].startDate) {
            tempData.data[i].startDate = new Date(tempData.data[i].startDate)
          }

          if (!!tempData.data[i].endDate) {
            tempData.data[i].endDate = new Date(tempData.data[i].endDate)
          }
        }
      }

      return {
        ...state,
        loading: false,
        [`${type}`]: tempData,
      }

    case FacilityActionType.GetFacilitiesFailure:
      type = _get(action, ['payload', 'type']) || 'hospitals'
      return {
        ...state,
        loading: false,
        [`${type}`]: {
          data: [],
          total: 0,
        },
      }

    case FacilityActionType.PostFacility:
      return {
        ...state,
        postLoading: true,
        facilityError: null,
      }

    case FacilityActionType.PostFacilitySuccess:
      type = _get(action, ['payload', 'type']) || 'hospitals'
      tempData = _get(action, ['payload', 'data', 'data']) || false
      tempSourceData = _get(action, ['payload', 'sourceData']) || {}
      temp = {}

      if (type === 'patient') {
      } else if (type === 'protocol') {
        const protocols = _get(state, ['activePatient', 'protocols']) || []
        temp['activePatient'] = {
          ...state.activePatient,
        }

        if (tempData === true) {
          const protocolIndex = _findIndex(
            protocols,
            (p: any) => p.uid === tempSourceData.uid
          )
          if (protocolIndex >= 0) {
            tempSourceData['tasks'] = (tempSourceData['tasks'] || []).filter(
              (t: any) => t.status !== 'Inactive'
            )
            protocols[protocolIndex] = tempSourceData
          }
        } else {
          tempSourceData['uid'] = tempData
          temp['activePatient']['protocols'] = [
            ...(temp['activePatient']['protocols'] || []),
            tempSourceData,
          ]
        }
      } else if (type === 'notes') {
        const notesData = _get(state, ['notes', 'data']) || []
        if (tempData === true) {
          const notesIndex = _findIndex(
            notesData,
            (n: any) => n.uid === tempSourceData.uid
          )
          if (notesIndex >= 0) {
            notesData[notesIndex] = tempSourceData
          }
        } else {
          tempSourceData['uid'] = tempData
          temp['notes'] = {
            data: [tempSourceData, ...notesData],
            total: notesData.length + 1,
          }
        }
      }

      return {
        ...state,
        postLoading: false,
        ...temp,
      }

    case FacilityActionType.PostFacilityFailure:
      return {
        ...state,
        postLoading: false,
        facilityError: action.payload,
      }

    case FacilityActionType.DeleteFacilities:
      return {
        ...state,
        loading: true,
        facilityError: null,
      }

    case FacilityActionType.DeleteFacilitiesSuccess:
      type = _get(action, ['payload', 'type']) || ''
      const uids = action.payload.data.map((d: any) => d.uid)
      tempData = _filter(
        _get(state, [type, 'data']) || [],
        (f: any) => uids.indexOf(f.uid) < 0
      )
      const total = _get(state, [type, 'total']) || 0

      return {
        ...state,
        loading: false,
        [`${type}`]: {
          data: tempData,
          total: Math.max(total - uids.length, 0),
        },
      }

    case FacilityActionType.DeleteFacilitiesFailure:
      return {
        ...state,
        loading: false,
        facilityError: action.payload,
      }

    case FacilityActionType.DeleteUsersFailure:
      return {
        ...state,
        loading: false,
        facilityError: action.payload,
      }

    case FacilityActionType.GetUserReportUsage:
      return {
        ...state,
        loadingUserReportUsage: true,
        userReportUsage: [],
        facilityError: null,
      }

    case FacilityActionType.GetUserReportUsageSuccess:
      tempData = _get(action, ['payload', 'data', 'data']) || []

      for (let i = 0; i < tempData.length; i++) {
        let fDate = new Date(tempData[i].date)
        var options = {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        }

        //let newDate = fDate.toLocaleDateString('en-US', options);
        let newDate = format(
          new Date(tempData[i].date),
          'MMM dd, yyyy (hh:mm a)'
        )
        //console.log(newDate);
        tempData[i].date = newDate
      }
      //console.log(tempData)

      return {
        ...state,
        loadingUserReportUsage: false,
        userReportUsage: tempData,
      }

    case FacilityActionType.GetUserReportUsageFailure:
      return {
        ...state,
        loadingUserReportUsage: false,
        userReportUsage: [],
      }

    case FacilityActionType.GetDataExport:
      return {
        ...state,
        loadingDataReport: true,
        dataReport: [],
        dataError: null,
      }

    case FacilityActionType.GetDataExportSuccess:
      tempData = _get(action, ['payload', 'data', 'data']) || []
      var exportSet = tempData.data;

      // if user is superuser, set exportDef to a certain set of fields
      if (action.payload.isSuperUser) {

        var exportDef = [
          {fieldName:"sessionUid",exportName:"Session Id"},
          {fieldName:"parentFacility",exportName:"Parent Facility"},
          {fieldName:"facility",exportName:"Facility"},
          {fieldName:"therapist",exportName:"Therapist"},
          {fieldName:"patientUid",exportName:"Patient ID"},
          {fieldName:"researchCode",exportName:"Research Code"},
          {fieldName:"testPatient",exportName:"Test Patient"},
          {fieldName:"startDate",exportName:"Task Start Date"},
          {fieldName:"startTime",exportName:"Task Start Time"},
          {fieldName:"endDate",exportName:"Task End Date"},
          {fieldName:"endTime",exportName:"Task End Time"},
          {fieldName:"appName",exportName:"Name of App"},
          {fieldName:"sessionTypeId",exportName:"Type of Session"},
          {fieldName:"taskName",exportName:"Task Name"},
          {fieldName:"difficultyLevel",exportName:"Difficulty"},
          {fieldName:"repetitionsRequested",exportName:"Repetitions Requested"},
          {fieldName:"secondsRequested",exportName:"Task Duration Setting (seconds)"},
          {fieldName:"taskStars",exportName:"Task Stars"},
          {fieldName:"taskHands",exportName:"Task Hands"},
          {fieldName:"taskSteps",exportName:"Task Steps"},
          {fieldName:"mpm",exportName:"Task MPM"},
          {fieldName:"tasksDuration",exportName:"Total Task Duration (seconds)"},
          {fieldName:"repetitions",exportName:"Repetitions Completed"},
          {fieldName:"maxRepDuration",exportName:"Max Rep Duration (seconds)"},
          {fieldName:"maxRepDurationL",exportName:"Max Rep DurationL"},
          {fieldName:"maxRepDurationR",exportName:"maxRepDurationR"},
          {fieldName:"totalRepsDurationL",exportName:"totalRepsDurationL"},
          {fieldName:"totalRepsDurationR",exportName:"totalRepsDurationR"},
          {fieldName:"totalRepsDuration",exportName:"totalRepsDuration"},
          {fieldName:"maxAngleL",exportName:"maxAngleL"},
          {fieldName:"maxAngleR",exportName:"maxAngleR"},
          {fieldName:"distance",exportName:"distance (inches)"},
          {fieldName:"height",exportName:"height (inches)"},
          {fieldName:"score",exportName:"score"},
          {fieldName:"levelReached",exportName:"levelReached"},
          {fieldName:"sessionStartDate",exportName:"Session Start Date"},
          {fieldName:"sessionStartTime",exportName:"Session Start Time"},
          {fieldName:"sessionEndDate",exportName:"Session End Date"},
          {fieldName:"sessionEndTime",exportName:"Session End Time"},
          {fieldName:"sessionDuration",exportName:"Session Duration (seconds)"},
          {fieldName:"totalSessionTaskTime",exportName:"Total Session Task Time (seconds)"},
          {fieldName:"totalMPM",exportName:"Session Avg MPM"}

        ]
      }
      else
      {
        // for non-superusers
        var exportDef = [
          {fieldName:"sessionUid",exportName:"Session Id"},
          {fieldName:"parentFacility",exportName:"Parent Facility"},
          {fieldName:"facility",exportName:"Facility"},
          {fieldName:"therapist",exportName:"Therapist"},
          {fieldName:"patientUid",exportName:"Patient ID"},
          {fieldName:"researchCode",exportName:"Research Code"},
          {fieldName:"startDate",exportName:"Task Start Date"},
          {fieldName:"startTime",exportName:"Task Start Time"},
          {fieldName:"endDate",exportName:"Task End Date"},
          {fieldName:"endTime",exportName:"Task End Time"},
          {fieldName:"appName",exportName:"Name of App"},
          {fieldName:"sessionTypeId",exportName:"Type of Session"},
          {fieldName:"taskName",exportName:"Task Name"},
          {fieldName:"difficultyLevel",exportName:"Difficulty"},
          {fieldName:"repetitionsRequested",exportName:"Repetitions Requested"},
          {fieldName:"secondsRequested",exportName:"Task Duration Setting (seconds)"},
          {fieldName:"taskStars",exportName:"Task Stars"},
          {fieldName:"tasksDuration",exportName:"Total Task Duration (seconds)"},
          {fieldName:"repetitions",exportName:"Repetitions Completed"},
          {fieldName:"score",exportName:"score"},
          {fieldName:"levelReached",exportName:"levelReached"},
          {fieldName:"sessionStartDate",exportName:"Session Start Date"},
          {fieldName:"sessionStartTime",exportName:"Session Start Time"},
          {fieldName:"sessionEndDate",exportName:"Session End Date"},
          {fieldName:"sessionEndTime",exportName:"Session End Time"},
          {fieldName:"sessionDuration",exportName:"Session Duration (seconds)"},
          {fieldName:"totalSessionTaskTime",exportName:"Total Session Task Time (seconds)"},
          {fieldName:"totalMPM",exportName:"Session Avg MPM"}

        ]
      }
    
      // header fields
      /////////////////////////
      var exportItemDelim = ','
      var exportLineDelim = '\n'
      var exportString = '';
      var numFields = exportDef.length;
      for (let i=0; i<numFields; i++) {
        var exportName = exportDef[i].exportName;
        exportString += exportName + exportItemDelim
      }
      exportString += exportLineDelim;

      // preprocess data to get timing information, which isn't returned from call
      /////////////////////////
      var sessionsHistory:any = {};
      var currSessionStartTime;
      var currSessionStartDate;
      var currentSessionEndDate;
      var currSessionStartFullDate = new Date();
      var currSessionEndFullDate = new Date();
      var currSessionEntireDuration = 0;
      var currSessionTasksDuration = 0;
      var currSessionUid = '';

      var numExportRecs = exportSet.length;
      for (var recNum=0; recNum<numExportRecs; recNum++) {
        //console.log("loop=>"+recNum);
        var tTask = exportSet[recNum];
        var thisSessionStartDate = tTask.sessionStartDate;
        var sessionUid = tTask.sessionUid;

        if ( !sessionsHistory.hasOwnProperty(sessionUid) )
        {
          // new session
          currSessionUid = sessionUid;

          if (sessionUid == "e0936c02-0ba5-4da8-b7f9-94aecd548346")
            {
              console.log("found session");
            }
          sessionsHistory[currSessionUid] = [];
          currSessionStartTime = tTask.sessionStartTime;
          currSessionStartDate = thisSessionStartDate;
          currentSessionEndDate = tTask.sessionEndDate;

          // if start or end date is missing, look for tasks with dates
          if (isNull(currSessionStartDate) || isNull(currentSessionEndDate)) {
            // what to do?
            console.log("missing session start or end date for sessionUid " + sessionUid);
          }

          currSessionStartFullDate = convertToDateObject(currSessionStartDate,currSessionStartTime);
          currSessionEndFullDate = convertToDateObject(tTask.sessionEndDate,tTask.sessionEndTime);
          currSessionTasksDuration = 0;

          let a = currSessionEndFullDate.getTime();
          let b = currSessionStartFullDate.getTime();
          currSessionEntireDuration = ( a - b )/1000;

          if (currSessionEntireDuration<0) {
            console.log("negative session time.")
          }
        }

        var taskStartFullDate = convertToDateObject(tTask.startDate,tTask.startTime);
        var taskEndFullDate = convertToDateObject(tTask.endDate,tTask.endTime);
        var taskDuration = Math.abs(taskEndFullDate.getTime() - taskStartFullDate.getTime()) / 1000;
        currSessionTasksDuration += taskDuration;
        tTask.tasksDuration = taskDuration;

        var thisTaskProps:any= {};

        for (let i=0; i<numFields; i++) {
          var fieldName = exportDef[i].fieldName;
          var fieldVal = '';
          if (tTask.hasOwnProperty(fieldName)) {
            fieldVal = tTask[fieldName]
          }
          if ( isNull(fieldVal) ) { fieldVal=''; }

          switch (fieldName) {

            case "sessionTypeId":
              switch (fieldVal + '') {
                case "3":
                  fieldVal = "Telehealth";
                  break;
                case "2":
                  fieldVal = "Solo";
                  break;
                default:
                  fieldVal = "Provider";
                  break;
              }
              break;
            case "taskName":
              fieldVal = "\"" + fieldVal + "\"";
              break;
            case "appName":
              // change ARWell Pro to ARWell PRO
              if (fieldVal === "ARWell Pro") { fieldVal = "ARWell PRO"; }
              break;
            case "sessionDuration":
              fieldVal = String(currSessionEntireDuration);
              break;
            case "totalSessionTaskTime":
              fieldVal = String(currSessionTasksDuration);
              break;
            case "mpm":
              var rndNumber = Math.round(parseFloat(fieldVal+''));
              fieldVal = rndNumber + ''; //String(round(Number(fieldVal)));
              break;
            case "totalRepsDuration":
              var rightDuration = parseFloat(tTask.totalRepsDurationR+'');
              var leftDuration = parseFloat(tTask.totalRepsDurationL+'');
              fieldVal = (Math.round((rightDuration+leftDuration)*100)/100) + '';  // round to 2 decimals
              break;
            case "difficultyLevel":
              // if the taskName is an ADL or Memory task, use it's value, else pick Easy, Medium or Hard (0,1,2)
              fieldVal = fieldVal + "";
              if (ATadlTasks.indexOf(tTask.taskName)>-1) {
                  if (fieldVal === '' || fieldVal === '0')
                  {
                    fieldVal = '5';
                  }
                  fieldVal = fieldVal + " cards";
              } else if (tTask.taskName.includes("Memory")) {
                if (fieldVal === '' || fieldVal === '0')
                  {
                    fieldVal = '4';
                  }
                  fieldVal = fieldVal + " cards";
              } else {
                switch (fieldVal) {
                  case "0":
                  case "1":
                    fieldVal = "Easy";
                    break;
                  case "2":
                  case "3":
                    fieldVal = "Medium";
                    break;
                  case "4":
                  case "5":
                    fieldVal = "Hard";
                    break;
                  default:
                    fieldVal = "Default";
                    break;
                }
              }
              break;

            default:
              // nothing
              break;

          }

          // error check
          if (fieldVal === 'NaN') { fieldVal = '';}

          thisTaskProps[fieldName] = fieldVal;
        }
        
        var currTaskHistoryForSession = sessionsHistory[currSessionUid];
        currTaskHistoryForSession.push(thisTaskProps);
      }

      var sessionIds = Object.keys(sessionsHistory);
      for (var uid of sessionIds) {
        var tSession = sessionsHistory[uid];
        var aggMpm = 0;
        var totalSecs = 0;
        var totalTasks = 0;
        for (var tTask of tSession) {
          aggMpm += toInteger(tTask.mpm);// * toInteger(tTask.tasksDuration);
          totalSecs = tTask.totalSessionTaskTime;
          totalTasks++;
        }
        var avgMPM = aggMpm/totalTasks; //totalSecs;
        avgMPM = Math.round(avgMPM);
        //var totalMPM = Math.round((avgMPM*totalSecs)/60);
        for (var tTask of tSession) {
          tTask.totalMPM = avgMPM; // totalMPM;
          tTask.totalSessionTaskTime = totalSecs; // update task with total time
        }
      }


      // create final export file
      ///////////////////////////

      for (var uid of sessionIds) {
        var tSession = sessionsHistory[uid];
        for (var tTask of tSession) {
          for (let i=0; i<numFields; i++) {
            var fieldName = exportDef[i].fieldName;
            var taskVal = tTask[fieldName];

            // don't report 0 values
            if (taskVal==0) { taskVal = ''; }

            exportString += taskVal + exportItemDelim;
          }

          exportString += exportLineDelim;
        }
      }

      var file_name = 'ATexport.csv'
      var element = document.createElement('a')
      element.setAttribute(
        'href',
        'data:text/plain;charset=utf-8,' + encodeURIComponent(exportString)
      )
      element.setAttribute('download', file_name)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)

      return {
        ...state,
        loadingDataReport: false,
        dataReport: [],
      }

    case FacilityActionType.GetDataExportFailure:
      return {
        ...state,
        loadingDataReport: false,
        dataReport: [],
      }

    case FacilityActionType.SetActiveFacility:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default facilitiesReducer
