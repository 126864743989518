export enum AuthActionType {
  AutoLogin = 'AUTO_LOGIN',
  RestoreSession = 'RESTORE_SESSION',
  RestoreSessionFailure = 'RESTORE_SESSION_FAILURE',
  Login = 'LOGIN',
  LoginSuccess = 'LOGIN_SUCCESS',
  LoginFailure = 'LOGIN_FAILURE',
  Logout = 'LOGOUT',

  ResetPasswordLink = 'RESET_PASSWORD_LINK',
  ResetPasswordLinkSuccess = 'RESET_PASSWORD_LINK_SUCCESS',
  ResetPasswordLinkFailure = 'RESET_PASSWORD_LINK_FAILURE',

  ResetPasswordTokenValidate = 'RESET_PASSWORD_TOKEN_VALIDATE',
  ResetPasswordTokenValidateSuccess = 'RESET_PASSWORD_TOKEN_VALIDATE_SUCCESS',
  ResetPasswordTokenValidateFailure = 'RESET_PASSWORD_TOKEN_VALIDATE_FAILURE',

  CompleteConsent = 'COMPLETE_CONSENT',
  CompleteConsentSuccess = 'COMPLETE_CONSENT_SUCCESS',
  CompleteConsentFailure = 'COMPLETE_CONSENT_FAILURE',

  CompleteSetup = 'COMPLETE_SETUP',
  CompleteSetupSuccess = 'COMPLETE_SETUP_SUCCESS',
  CompleteSetupFailure = 'COMPLETE_SETUP_FAILURE',
  
  ResetPassword = 'RESET_PASSWORD',
  ResetPasswordSuccess = 'RESET_PASSWORD_SUCCESS',
  ResetPasswordFailure = 'RESET_PASSWORD_FAILURE',

  UpdatePassword = 'UPDATE_PASSWORD',
  UpdatePasswordSuccess = 'UPDATE_PASSWORD_SUCCESS',
  UpdatePasswordFailure = 'UPDATE_PASSWORD_FAILURE',

  UpdateAuthErrors = 'UPDATE_AUTH_ERRORS'
}