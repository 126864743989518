import axios from 'axios';
import URLSearchParams from 'url-search-params';
import {
  APIURL,
  client,
  atAPIOptions,
} from './at-app-api';

export const atLogin = (username: string, password: string) => {
  let body = new URLSearchParams();
  body.set('grant_type', 'password');
  body.set('username', username);
  body.set('password', password);
  body.set('client_id', 'at');
  body.set('client_secret', '8A2DEAFC-7836-4104-A830-70EDE733D152');
  return axios.post(`${APIURL}/connect/token`, body.toString());
};


// TODO: this api call needs implementation
export const atRefreshLogin = (refresh_token: string) => {
  const data = {
    refresh_token: refresh_token
  };

  const request = client();
  return request.post(
    `${APIURL}/user/refreshLogin`,
    data,
  );
};

export const atLogout = () => {
  const request = client('application/x-www-form-urlencoded');
  const data = {};
  return request.post(
    `${APIURL}/connect/logout`,
    data,
  );
};

export const atResetPasswordLink = (email: string) => {
  const data = {
    email: email
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/password/resetlink`,
    data,
  );
};

export const atResetPasswordTokenValidate = (username: string, code: string) => {
  const data = {
    username: username,
    code: code,
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/password/token/validate`,
    data,
  );
};

export const atCompleteConsent = (username: string, code: string) => {
  const data = {
    username: username,
    code: code,
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/consent`,
    data,
  );
};

export const atResetPassword = (username: string, password: string, code: string) => {
  const data = {
    username: username,
    password: password,
    code: code,
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/password/reset`,
    data,
  );
};

export const atCompleteSetup = (username: string, password: string, code: string) => {
  const data = {
    username: username,
    password: password,
    code: code,
    newUser: true,
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/password/reset`,
    data,
  );
};

export const atUpdatePassword = (username: string, currentpassword: string, newpassword: string) => {
  const data = {
    username: username,
    currentpassword: currentpassword,
    newpassword: newpassword
  };

  const request = client();
  return request.post(
    `${APIURL}/api/account/password/update`,
    data,
  );
};
