import {
  APIURL,
  client,
  atAPIOptions,
} from './at-app-api';
import { IFacility } from '../store/facilities/facilities-redux.model';

export const atGetFacilities = (payload: any) => {
  const {
    type,
    subType,
    targetType,
    parentFacilityUid,
    facilityUid,
    parentUid,
    uid,
    skip,
    take,
    active,
  } = payload;

  let params: any = {};

  if (skip || skip == 0)
    params['skip'] = `${skip}`;
  if (take)
    params['take'] = `${take}`;
  if (active != null)
    params['active'] = active;

  // remove 'active' from params if targetType is NOT 'patients' or 'archivedPatients'
  if (targetType !== 'patients' && targetType !== 'archivedPatients')
    delete params.active;

  const request = client();
  let url = '';

  switch (targetType) {
    case 'hospitals':
      url = `${APIURL}/api/facility`;
      params['type'] = 1;
      break;

    case 'clinics':
      url = `${APIURL}/api/facility`;
      params['type'] = 2;
      if (parentUid)
        params['parentUid'] = parentUid;
      break;

    case 'staff':
      url = `${APIURL}/api/user`;

      params['role'] = 'Staff';

      if (parentFacilityUid)
        params['parentFacilityUid'] = parentFacilityUid;

      if (facilityUid)
        params['facilityUid'] = facilityUid;
      break;

    case 'activeStaff':
      url = `${APIURL}/api/user/${uid}`;
      break;

    case 'activeClinic':
      url = `${APIURL}/api/facility/${facilityUid}`;
      break;
  
    case 'therapists':
      url = `${APIURL}/api/user`;

      if (parentFacilityUid)
        params['parentFacilityUid'] = parentFacilityUid;

      if (facilityUid)
        params['facilityUid'] = facilityUid;

      break;

    case 'activeTherapist':
      url = `${APIURL}/api/user/${uid}`;
      break;

    case 'patients':
      url = `${APIURL}/api/user/enduser`;
      if (parentUid)
        params['userUid'] = parentUid;
      break;

    case 'archivedPatients':
      url = `${APIURL}/api/user/enduser`;
      params['active'] = false;
      break;

    case 'activePatient':
      url += `${APIURL}/api/user/${uid}`;
      params = {};
      // params['dt'] = '2020-02-12';
      break;
    case 'sessions':
      params['patientUid'] = '927789b2-5b5c-44f4-a1b0-08d78ce1b176';
      url = `${APIURL}/api/session/v2`;
      if (parentUid)
        params['patientUid'] = parentUid;
        break;

    case 'protocols':
      break;

    case 'notes':
      url = `${APIURL}/api/notes/patient/${parentUid}`;
      break;
  }

  return request.get(
    url,
    {
      params: params
    }
  );
};

export const atGetUserReportUsage = (payload: any) => {
  const {
    uid,
  } = payload;

  let params: any = {};
  const request = client();
  let url = `${APIURL}/api/report/user/usage`;

  if (uid) {
    params['uid'] = uid;
  }


  return request.get(
    url,
    {
      params: params
    }
  );
};

export const atGetDataDownload = (payload: any) => {
  const {
    facilityUid,
    startDate,
    endDate,
  } = payload;

  /////// NOTE: The call commmented below gets session summaries, not full data on each task
  //let url = `${APIURL}/api/session/all`;
  /////////////////////////////////////////

  let url = `${APIURL}/api/session/fulldata`;
  const params: any = {};

  if (facilityUid) {
    params['facilityUid'] = facilityUid;
  }
  if (startDate) {
    params['startDate'] = startDate;
  }
  if (endDate) {
    params['endDate'] = endDate;
  }

  const request = client();

  return request.get(
    url,
    {
      params: params
    }
  );
};

export const atPostFacility = (payload: any) => {
  const {
    type,
    subType,
    targetType,
    body // IFacility || IUser
  } = payload;
  const request = client();
  let url = '';

  switch (targetType) {
    case 'hospitals':
    case 'clinics':
      url = `${APIURL}/api/facility`;
      break;
    case 'therapists':
      url = `${APIURL}/api/user`;
      break;
    case 'staff':
        url = `${APIURL}/api/user`;
        break;
    case 'patients':
      url = `${APIURL}/api/user`; //patient/v2`;
      break;
    case 'sessions':
      url = `${APIURL}/api/session/v2`;
      break;
    case 'protocol':
      url = `${APIURL}/api/protocol`;
      break;
    case 'notes':
      url = `${APIURL}/api/notes`;
      break;
  }

  if (body.uid) {
    return request.put(
      url,
      body
    )
  }

  return request.post(
    url,
    body
  );
};

export const atPostSendInvite = (payload: any) => {
  const {
    body // IFacility || IUser
  } = payload;
  const request = client();
  let url = `${APIURL}/api/user/invite/${body[0].uid}`;
    
  if (body.uid) {
    return request.put(
      url,
      body
    )
  }

  return request.post(
    url,
    body
  );
};

export const atPostSendConsent = (payload: any) => {
  const {
    body // IFacility || IUser
  } = payload;
  const request = client();
  let url = `${APIURL}/api/patient/sendConsentEmail`;

  return request.post(
    url,
    body
  );
};

export const atRecoverUsers = (payload: any) => {
  const {
    body // IFacility || IUser
  } = payload;
  const request = client();
  let data = {
    isActive: true
  }
  payload.isActive = true;
  let url = `${APIURL}/api/patient/${body[0].uid}/updateStatus`;
    
  return request.post(
    url,
    data
  );
};

export const atRemoveUsers = (payload: any) => {
  const {
    body // IFacility || IUser
  } = payload;
  const request = client();
  let data = {
    isActive: false
  }
  payload.isActive = false;
  let url = `${APIURL}/api/patient/${body[0].uid}/updateStatus`;
    
  return request.post(
    url,
    data
  );
};

export const atDeleteFacilities = (payload: any) => {
  const {
    type,
    subType,
    targetType,
    body,
  } = payload;
  let url = '';
  let data = {};

  switch (targetType) {
    case 'hospitals':
    case 'clinics':
      url = `${APIURL}/api/facility`;
      data = body[0];
      break;
    case 'staff':
    case 'therapists':
    case 'patients':
      url = `${APIURL}/api/user/${body[0].uid}`;
      break;
    case 'sessions':
      url = `${APIURL}/api/session/v2/${body[0].uid}`;
      break;
    case 'protocols':
      url = `${APIURL}/api/protocol/${body[0].uid}`;
      break;
    case 'notes':
      url = `${APIURL}/api/notes/${body[0].uid}`;
      break;
  }

  const request = client();
  return request.delete(
    url,
    {
      data: data,
    }
  );
};
