/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo, useCallback, useContext, useRef} from 'react';
import { navigate, setBasepath } from 'hookrouter';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
  Hidden,
  colors,
  Link,
  Theme,
  Menu,
  MenuItem
} from '@material-ui/core';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {
  UserRoles
} from '../../settings/user-roles';
import {
  AuthActionType
} from '../../store/auth/auth-action-types';
import { getActiveFacilityPayload } from '../../utils/facility';
import { FacilityActionType } from '../../store/facilities/facilities-action-types';
import {
  get as _get
} from 'lodash';
import UserQRCode from '../../pages/facilities/components/qrcodes/qrcode';
import { ATtherapistRemoteURL } from '../../settings/app';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme : Theme) => ({
  root: {
    boxShadow: '0px 1px 1px rgba(198,222,128,0.8)',
    color: 'white',
  },
  flexGrow: {
    flexGrow: 1
  },
  search: {
    backgroundColor: 'rgba(255,255,255, 0.1)',
    borderRadius: 4,
    flexBasis: 300,
    height: 36,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center'
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: 'inherit'
  },
  searchInput: {
    flexGrow: 1,
    color: 'inherit',
    '& input::placeholder': {
      opacity: 1,
      color: 'inherit'
    }
  },
  searchPopper: {
    zIndex: theme.zIndex.appBar + 100
  },
  searchPopperContent: {
    marginTop: theme.spacing(1)
  },
  topNavButton: {
    color: 'rgba(88,106,135, 1)', //colors.grey[800],
    fontFamily: 'Open Sans',
    textTransform: 'none',
    marginLeft: '30px',
    '&$active': {
      color: colors.green[900],
    },
  },
  telehealthButton: {
    color: colors.common.white,
    backgroundColor: colors.green[400],
    '&:hover': {
      backgroundColor: colors.green[900]
    },
    marginLeft: '30px'
  },
  trialButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  trialIcon: {
    marginRight: theme.spacing(1)
  },
  menuButton: {
    color: 'rgba(88,106,135, 1)',
    marginRight: theme.spacing(1)
  },
  chatButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsButton: {
    marginLeft: theme.spacing(1)
  },
  notificationsBadge: {
    backgroundColor: colors.orange[600]
  },
  logoutButton: {
    marginLeft: theme.spacing(1)
  },
  logoutIcon: {
    marginRight: theme.spacing(1)
  },
  middleNav: {
    verticalAlign: 'middle'
  }
}));

const TopBar = ({
  userProfile,
  onOpenNavBarMobile,
  className,
  ...rest
} : {
    userProfile: any,
    onOpenNavBarMobile: any,
    className?: string
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [bShowQR, setShowQR] = useState(false);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch({type: AuthActionType.Logout, payload: null});
  };

  const handleEditProfile = (userInfo: any) => {
    //var tUser = userProfile;
    var profileRole: string;
    switch(userProfile.role) 
      {
        case "EndUser":
          profileRole = "patients";
          break;

        case "User":
          profileRole = "therapists";
          break;

        default:
          profileRole = "staff";
          break;
      }
    var payload: any = getActiveFacilityPayload(profileRole, '');
    
    if (profileRole === "patients") {
      payload.activePatient = userInfo;
    } else if (profileRole === "therapists") {
      payload.activeTherapist = userInfo;
    }
    else {
      payload.activeStaff = userInfo;
    }
    
    dispatch({ type: FacilityActionType.SetActiveFacility, payload: payload });

    navigate(`/users/${profileRole}/edit`, false, {uid: userProfile.uid});
    setAnchorEl(null);
  };

  const handleExport = (userInfo: any) => {
    //var tUser = userProfile;
    navigate(`/exportdata`);
    setAnchorEl(null);
    //dispatch({ type: FacilityActionType.GetDataExport, payload: payload });
  };
  
  
  const handleResources = () => {
    navigate(`/resources`);
    setAnchorEl(null);
  };
  const handleTutorials = () => {
    navigate(`/tutorials`);
    setAnchorEl(null);
  };
  const handleTherapistDash = () => {
    navigate(`/users/therapists/${userProfile.uid}`);
    setAnchorEl(null);
  };
  const handleTelehealth = () => {
    navigate(`/telehealth`);
  };
  const openLink = (strLink: string) => {
    window.open( strLink, "_blank"); 
  }
  const openQR = () => {
    setShowQR(true);
  }
  const toggleOpenModal = () => {
    setShowQR( !bShowQR) ;
  };

  const shortFirstName = () => {
    return (userProfile.firstName ? userProfile.firstName.substring(0, 10) : '');
  }
  useEffect(() => {
    let mounted = true;

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="inherit"
    >
      <Toolbar>
        {
          [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN].indexOf(userProfile.role) >= 0 && (
            <Hidden lgUp>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                onClick={onOpenNavBarMobile}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          )
        }
        {
          [UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN].indexOf(userProfile.role) >= 0 && (  
            <Link onClick={() => navigate('/')}>
            <img
              className="testLogo"
              alt="Logo"
              src="./images/headerLogo.png" //logo_100.png"
              //width="75px"
              />
            </Link>
          )
        }
        {
          [UserRoles.USER].indexOf(userProfile.role) >= 0 && (  
            <div className={classes.middleNav}>
              <Link onClick={() => navigate(`/users/therapists/${userProfile.uid}`)}>
              <img
                className="testLogo"
                alt="Logo"
                src="./images/headerLogo.png"
                //width="75px"
              />
              </Link>
            </div>
          )
        }
        {
          [UserRoles.ENDUSER].indexOf(userProfile.role) >= 0 && (  
            <Link onClick={() => navigate(`/users/patients/${userProfile.uid}`)}>
            <img
              className="testLogo"
              alt="Logo"
              src="./images/headerLogo.png"
              //width="75px"
            />
          </Link>
          )
        }

      <div className={classes.flexGrow} />

      <Hidden smDown>
        {
          [ UserRoles.ENDUSER, UserRoles.USER ].indexOf(userProfile.role) >= 0 && (
          <Button
            title="Badge"
            className={classes.topNavButton}
            onClick={openQR}
          >
           <AssignmentIndIcon/> Badge
          </Button>
          )
        }
        {
          [ UserRoles.USER ].indexOf(userProfile.role) >= 0 && (
            <Button
              title="Resources"
              className={classes.topNavButton}
              onClick={handleResources}
            >
              Resources
            </Button>
          )
        }
        {
          [ UserRoles.USER ].indexOf(userProfile.role) >= 0 && (
            <Button
            title="Tutorials"
            className={classes.topNavButton}
            onClick={handleTutorials}
          >
            Tutorials
          </Button>
          )
        }
        {
          [ UserRoles.ENDUSER ].indexOf(userProfile.role) >= 0 && (
          <Button
            title="Setup"
            className={classes.topNavButton}
            onClick={() => openLink("https://www.augmenttherapy.com/arwellpro")}
          >
            SETUP INSTRUCTIONS
          </Button>
          )
        }

          
        <Button
          className={classes.topNavButton}
          aria-controls="user-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          {userProfile.firstName || ''}&nbsp; {userProfile.lastName || ''}&nbsp;
          <AccountCircleIcon className={classes.logoutIcon} />
        </Button>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
          [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN, UserRoles.USER ].indexOf(userProfile.role) >= 0 && (
          <MenuItem onClick={() => handleExport(userProfile)}>Export Data</MenuItem>
          )
          }
          <MenuItem onClick={() => handleEditProfile(userProfile)}>Edit Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Hidden>


      <Hidden mdUp>

        <Button
          className={classes.topNavButton}
          aria-controls="user-menu-sm"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <AccountCircleIcon className={classes.logoutIcon}/>
        </Button>
        <Menu
          id="user-menu-sm-small"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => openQR()}>Sign In Badge</MenuItem>
          {
            [ UserRoles.USER ].indexOf(userProfile.role) >= 0 && (
              <MenuItem onClick={() => handleTherapistDash()}>Dashboard</MenuItem>
            )
          }
          {
            [ UserRoles.ENDUSER ].indexOf(userProfile.role) < 0 && (
              <MenuItem onClick={() => handleResources()}>Resources</MenuItem>
            )
          }
          {
            [ UserRoles.ENDUSER ].indexOf(userProfile.role) < 0 && (
              <MenuItem onClick={() => handleTutorials()}>Tutorials</MenuItem>
            )
          }
           {
          [ UserRoles.SUPERUSER, UserRoles.COMPANYADMIN].indexOf(userProfile.role) >= 0 && (
          <MenuItem onClick={() => handleExport(userProfile)}>Export Data</MenuItem>
          )
          }
          
          <MenuItem onClick={() => handleEditProfile(userProfile)}>Edit Profile</MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Hidden>

      </Toolbar>

      {
        (bShowQR === true) && (
            <UserQRCode
              id="qrCode"
              qrCodeString={userProfile.qr}
              size={256}
              userfullname={userProfile.firstName}
              subType={userProfile.role}
              open={bShowQR}
              handleClose={toggleOpenModal}
            />
        )
      }

    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onOpenNavBarMobile: PropTypes.func,
  userProfile: PropTypes.any
};

export default TopBar;
