import {
  get as _get
} from 'lodash';
import {
  IAuthState,
} from './auth-redux.model';

import { AuthActionType } from './auth-action-types';

export const initialAuthState: IAuthState = {
  authentication: null,
  authErrors: null,
  loginRequest: false,
  resetPasswordLinkRequest: false,
  resetPasswordTokenValidateRequest: false,
  resetPasswordTokenValidated: false,
  resetPasswordRequest: false,
  updatePasswordRequest: false,
  completeSetupRequest: false,
}

const authReducer = (state: IAuthState = initialAuthState, action: any) => {
  switch (action.type) {
    case AuthActionType.Login:
      return {
        ...state,
        authentication: null,
        authErrors: null,
        loginRequest: true,
      };

    case AuthActionType.LoginSuccess:
      // const authentication = {
      //   token_type: 'Bearer',
      //   access_token: 'CfDJ8LVw4cRYJVZKuYYk-ZVCLlaFdMkWut8gvup2vCarYzkfOtrNzP5yltc-EtvGN-BqWdcnrUDvu9rjLs_9cwliOkuEB-H-W1FvT_LJDDC5QRbuNRyvwfFh2ssPHZQL8z7y8M1-phjvy6e9IyujdDBN2JvtfcBBuJaLoCQ6wDtzuAT6Z2SKrH8233RjMumhpajkfynM8qJ6g5ZohKX6S5VNvb03a7a1vLepD1Rkl_QvzUnR_Cd2neWEZ9wtoJlwggUdMJl5yVtmezhwCkYY3-nuMxU9tWvOKT8O7TCuT0Ns0aGABEjdcFK_GG_yYxPFLw0rdnKlrL3-fNDeek14JiWJUDJAY62G1M0a7nvwqjVWoN7qNNMJ1wBxOBBCStCSWnKVNDKQfphDvDVL_vrygSante6lLO3WgM-p_KAYGp6COmnt4Wuhlv3zp81L9jdwjZZg9wt0vdRzT4kHGqGZgX3i7mX6BWWsuDd1JC3rwpzRTAeVQ0JjBLw_yjdnVPGD1_jZElfKLgAMk7T9bjRm7rwwOS8rADis5CSaYZgbXEc_vk9X12BPp2I3fT6ysINj5FoHayktMTbNuqg58mMAzDTc0Oto5D5pnnf8CVdf2plgT-zwf3qTuM7tAhaQ25S6IPcmig7EE6ExqcsRMSOgEWBe4-0',
      //   expires_in: 86400,
      //   refresh_token: ''
      // }

      const authentication = action.payload.data;

      sessionStorage.setItem('at_api_token_type', authentication.token_type);
      sessionStorage.setItem('at_api_access_token', authentication.access_token);
      sessionStorage.setItem('at_api_expires_in', `${authentication.expires_in}`);
      sessionStorage.setItem('at_api_refresh_token', authentication.refresh_token);

      return {
        ...state,
        authentication: authentication,
        loginRequest: false,
      };

    case AuthActionType.RestoreSessionFailure:
    sessionStorage.removeItem('at_api_token_type');
    sessionStorage.removeItem('at_api_access_token');
    sessionStorage.removeItem('at_api_expires_in');
    sessionStorage.removeItem('at_api_refresh_token');
      return {
        ...state,
        authentication: null,
        authErrors: null,
        loginRequest: false,
      };
      
    case AuthActionType.LoginFailure:
      return {
        ...state,
        authentication: null,
        authErrors: action.payload,
        loginRequest: false,
      };

    case AuthActionType.ResetPasswordLink:
      return {
        ...state,
        authErrors: null,
        resetPasswordLinkRequest: true,
      };
    case AuthActionType.ResetPasswordLinkSuccess:
      return {
        ...state,
        resetPasswordLinkRequest: false,
      };
    case AuthActionType.ResetPasswordLinkFailure:
      return {
        ...state,
        authErrors: action.payload,
        resetPasswordLinkRequest: false,
      };

    case AuthActionType.ResetPasswordTokenValidate:
      return {
        ...state,
        authErrors: null,
        resetPasswordTokenValidateRequest: true,
        resetPasswordTokenValidated: false,
      };
    case AuthActionType.ResetPasswordTokenValidateSuccess:
      return {
        ...state,
        resetPasswordTokenValidateRequest: false,
        resetPasswordTokenValidated: _get(action, ['payload', 'data']) || false,
      };
    case AuthActionType.ResetPasswordTokenValidateFailure:
      return {
        ...state,
        authErrors: action.payload,
        resetPasswordTokenValidateRequest: false,
        resetPasswordTokenValidated: false,
      };

    case AuthActionType.ResetPassword:
      return {
        ...state,
        authErrors: null,
        resetPasswordRequest: true,
      };
    case AuthActionType.ResetPasswordSuccess:
      return {
        ...state,
        resetPasswordRequest: false,
      };
    case AuthActionType.ResetPasswordFailure:
      return {
        ...state,
        authErrors: action.payload,
        resetPasswordRequest: false,
      };

    case AuthActionType.UpdatePassword:
      return {
        ...state,
        authErrors: null,
        updatePasswordRequest: true,
      };
    case AuthActionType.UpdatePasswordSuccess:
      return {
        ...state,
        updatePasswordRequest: false,
      };
    case AuthActionType.UpdatePasswordFailure:
      return {
        ...state,
        authErrors: action.payload,
        updatePasswordRequest: false,
      };

    case AuthActionType.CompleteSetup:
      return {
        ...state,
        authErrors: null,
        completeSetupRequest: true,
      };
    case AuthActionType.CompleteSetupSuccess:
      return {
        ...state,
        completeSetupRequest: false,
      };
    case AuthActionType.CompleteSetupFailure:
      return {
        ...state,
        authErrors: action.payload,
        completeSetupRequest: false,
      };
    default:
      return state
  }
}

export default authReducer;
