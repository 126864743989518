import React, { useContext, useEffect, useState } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { usePath, useRoutes, useRedirect } from "hookrouter";
import { navigate } from "hookrouter";
import { get as _get } from "lodash";
import { UserRoles } from './settings/user-roles';
import { authSagas } from "./store/auth/auth.saga";
import { AuthActionType } from "./store/auth/auth-action-types";
import { UserActionType } from "./store/user/user-action-types";

const ProtectedRoute = (props: any) => {
  const dispatch = useDispatch();
  const { component } = props;

  const path = usePath();
  const authentication = useSelector(
    (state) => _get(state, ["auth", "authentication"]) || null
  );
  const userProfile = useSelector(
    (state) => _get(state, ["user", "profile"]) || null,
    shallowEqual
  );

  const handleLogin = () => {
    navigate(`/login`);
  };

  useEffect(() => {
    if (!authentication || !userProfile) {
      //check session storage for existing access token, if found - load the profile for the user
      if (sessionStorage && Boolean(sessionStorage.getItem('at_api_access_token'))){
        dispatch({ type: AuthActionType.RestoreSession });
        return; //Show a loader?
      } else {
        handleLogin();
      }
    } else if (_get(userProfile, ['unreadAgreements', 'length'])) {
      navigate(`/agreement`);
    }
  }, [authentication, userProfile, path, dispatch]);

  if (!authentication || !userProfile || _get(userProfile, ['role']) === UserRoles.USER && _get(userProfile, ['unreadAgreements', 'length']))
    return null;

  return component;
};

export default ProtectedRoute;
