export enum FacilityActionType {
  GetFacilities = 'GET_FACILITIES',
  GetFacilitiesSuccess = 'GET_FACILITIES_SUCCESS',
  GetFacilitiesFailure = 'GET_FACILITIES_FAILURE',

  PostFacility = 'POST_FACILITY',
  PostFacilitySuccess = 'POST_FACILITY_SUCCESS',
  PostFacilityFailure = 'POST_FACILITY_FAILURE',

  DeleteFacilities = 'DELETE_FACILITIES',
  DeleteFacilitiesSuccess = 'DELETE_FACILITIES_SUCCESS',
  DeleteFacilitiesFailure = 'DELETE_FACILITIES_FAILURE',

  GetUsers = 'GET_USERS',
  GetUsersSuccess = 'USERS_GET_SUCCESS',
  GetUsersFailure = 'USERS_GET_FAILURE',

  PostUser = 'POST_USER',
  PostUserSuccess = 'POST_USER_SUCCESS',
  PostUserFailure = 'POST_USER_FAILURE',

  DeleteUsers = 'DELETE_USERS',
  DeleteUsersSuccess = 'DELETE_USERS_SUCCESS',
  DeleteUsersFailure = 'DELETE_USERS_FAILURE',

  RemoveUsers = 'REMOVE_USERS',
  RemoveUsersSuccess = 'REMOVE_USERS_SUCCESS',
  RemoveUsersFailure = 'REMOVE_USERS_FAILURE',

  RecoverUsers = 'RECOVER_USERS',
  RecoverUsersSuccess = 'RECOVER_USERS_SUCCESS',
  RecoverUsersFailure = 'RECOVER_USERS_FAILURE',

  GetUserReportUsage = 'GET_USER_REPORT_USAGE',
  GetUserReportUsageSuccess = 'GET_USER_REPORT_USAGE_SUCCESS',
  GetUserReportUsageFailure = 'GET_USER_REPORT_USAGE_FAILURE',

  GetDataExport = 'GET_DATA_EXPORT',
  GetDataExportSuccess = 'GET_DATA_EXPORT_SUCCESS',
  GetDataExportFailure = 'GET_DATA_EXPORT_FAILURE',

  PostUserSendInvite = 'POST_USER_SEND_INVITE',
  PostUserSendInviteSuccess = 'POST_USER_SEND_INVITE_SUCCESS',
  PostUserSendInviteFailure = 'POST_USER_SEND_INVITE_FAILURE',

  PostUserSendConsent = 'POST_USER_SEND_CONSENT',
  PostUserSendConsentSuccess = 'POST_USER_SEND_CONSENT_SUCCESS',
  PostUserSendConsentFailure = 'POST_USER_SEND_CONSENT_FAILURE',

  SetActiveFacility = 'SET_ACTIVE_FACILITY',
}