import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styled from "styled-components";
import {
	get as _get
} from 'lodash';

import {
  makeStyles,
} from '@material-ui/core/styles';
import 'date-fns';
import MomentUtils from '@date-io/moment';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const StyledKeyboardDatePicker = styled(KeyboardDatePicker)`
  ${({ theme }) => `
  `}
`;

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    }
	},
	helperText: {
		padding: '0 10px',
	},
}));

const AtDatePicker = (props: any) => {
  const {
    className,
    errors,
    ...rest
  } = props;

  const classes = useStyles();
  const error = _get(errors, [props.name || '']);

  return (
    <MuiPickersUtilsProvider
      utils={DateFnsUtils}>
      <FormControl
        className={clsx(classes.formControl, className)}
        error={error ? true : false}>

        <StyledKeyboardDatePicker
          {...rest}
        />
        {
          error ? (
            <FormHelperText className={classes.helperText}>
              <b>{error.message}</b>
            </FormHelperText>
          ) : null
        }
      </FormControl>
    </MuiPickersUtilsProvider>
  );
}

AtDatePicker.defaultProps = {
  errors: {},
};

export default AtDatePicker;
