import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import {
  get as _get,
  take
} from 'lodash';

import { FacilityActionType } from './facilities-action-types';
import { AuthActionType } from '../auth/auth-action-types';

import { Navigations } from '../../settings/nav-config';
import { getAtError } from '../../settings/error';

import {
  atGetFacilities,
  atPostFacility,
  atDeleteFacilities,
  atGetUserReportUsage,
  atGetDataDownload,
  atPostSendInvite,
  atPostSendConsent,
  atRecoverUsers,
  atRemoveUsers,
} from '../../services/at-app-facilities-api';

function* getFacilities(action: any) {
  try {
    const data = yield call(
      atGetFacilities,
      action.payload
    );
    yield put({ type: FacilityActionType.GetFacilitiesSuccess, payload: { type: action.payload.targetType, data: data } });
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.GetFacilitiesFailure, message: error });
    }
  }
}

function* getUserReportUsage(action: any) {
  try {
    const data = yield call(
      atGetUserReportUsage,
      action.payload
    );
    yield put({ type: FacilityActionType.GetUserReportUsageSuccess, payload: { type: action.payload.targetType, data: data } });
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.GetUserReportUsageFailure, message: error });
    }
  }
}

function* getDataDownload(action: any) {
  try {
    const data = yield call(
      atGetDataDownload,
      action.payload
    );
    yield put({ type: FacilityActionType.GetDataExportSuccess, payload: { type: action.payload.targetType, data: data } });
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.GetDataExportFailure, message: error });
    }
  }
}
function* postFacility(action: any) {
  try {
    const data = yield call(atPostFacility, action.payload);
    yield put({ type: FacilityActionType.PostFacilitySuccess, payload: { type: action.payload.targetType, data: data, sourceData: action.payload.body} });
    toast.success(`Save successful`);
    if (
      [
        Navigations.facilities.hospitals,
        Navigations.facilities.clinics,
        Navigations.users.therapists,
        Navigations.users.patients,
        Navigations.users.staff,
      ].indexOf(action.payload.targetType) >=0
    ) {
      window.history.back();
    }
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.PostFacilityFailure, message: error });
    }
  }
}

function* deleteFacilities(action: any) {
  try {
    const data = yield call(atDeleteFacilities, action.payload);
    yield put({ type: FacilityActionType.DeleteFacilitiesSuccess, payload: { type: action.payload.targetType, data: action.payload.body } });
    toast.success(`The account has been deleted.`);
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.DeleteFacilitiesFailure, message: error });
    }
  }
}

function* recoverUsers(action: any) {
  try {
    const data = yield call(atRecoverUsers, action.payload);
    yield put({ type: FacilityActionType.RecoverUsersSuccess, payload: { type: action.payload.targetType, data: action.payload.body } });
    toast.success(`The account has been recovered.`);
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.RecoverUsersFailure, message: error });
    }
  }
}

function* removeUsers(action: any) {
  try {
    const data = yield call(atRemoveUsers, action.payload);
    yield put({ type: FacilityActionType.RemoveUsersSuccess, payload: { type: action.payload.targetType, data: action.payload.body } });
    toast.success(`The user account has been removed.`);
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.RemoveUsersFailure, message: error });
    }
  }
}

function* postSendInvite(action: any) {
  try {
    const data = yield call(atPostSendInvite, action.payload);
    yield put({ type: FacilityActionType.PostUserSendInviteSuccess, payload: { type: action.payload.targetType, data: data, sourceData: action.payload.body} });
    toast.success(`The email has been sent!`);
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.PostUserSendInviteFailure, message: error });
    }
  }
}

function* postSendConsent(action: any) {
  try {
    const data = yield call(atPostSendConsent, action.payload);
    yield put({ type: FacilityActionType.PostUserSendConsentSuccess, payload: { type: action.payload.targetType, data: data, sourceData: action.payload.body} });
    toast.success(`The email has been sent!`);
  } catch (err) {
    if (err.response.status === 401) {
      yield put({ type: AuthActionType.Logout, payload: null });
    } else {
      const error = getAtError(err);
      toast.error(`${error}`);
      yield put({ type: FacilityActionType.PostUserSendConsentFailure, message: error });
    }
  }
}
const facilitiesSagas = [
  takeEvery(FacilityActionType.GetFacilities, getFacilities),
  takeEvery(FacilityActionType.PostFacility, postFacility),
  takeEvery(FacilityActionType.DeleteFacilities, deleteFacilities),
  takeEvery(FacilityActionType.GetUserReportUsage, getUserReportUsage),
  takeEvery(FacilityActionType.GetDataExport, getDataDownload),
  takeEvery(FacilityActionType.PostUserSendInvite, postSendInvite),
  takeEvery(FacilityActionType.PostUserSendConsent, postSendConsent),
  takeEvery(FacilityActionType.RecoverUsers, recoverUsers),
  takeEvery(FacilityActionType.RemoveUsers, removeUsers),
];

export {
    facilitiesSagas,
}
