import React from 'react';
import { navigate, usePath, getQueryParams, useQueryParams, setQueryParams } from 'hookrouter';
import { colors } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import FaceIcon from '@material-ui/icons/Face';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import HelpCenterIcon from '@material-ui/icons/Help';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import ListIcon from '@material-ui/icons/List';
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle';

import {
  UserRoles
} from './user-roles';

export const Navigations = {
  auth: {
    login:   '/login',
    autologin: '/autologin',
    agreement: '/agreement',
    completeConsent: '/complete-consent',
    accountSecurity: {
      root: '',
      resetPasswordLink: 'reset-password',
      resetPassword: 'confirm-reset',
      completeSetup: 'complete-setup',
      updatePassword: 'update-password',
      consentSuccess: 'consent-success',
      consentFailure: 'consent-failure',
    },
  },
  dashboard: {
    root: '/dashboard',
    default: 'default',
    analytics: 'analytics',
  },
  facilities: {
    root: '/facilities',
    hospitals: 'hospitals',
    clinics: 'clinics',
    default: 'default',
  },
  users: {
    root: '/users',
    staff: 'staff',
    therapists: 'therapists',
    patients: 'patients',
    default: 'default',
  },
  sessions: {
    root: '/sessions',
    sessions: 'sessions',
    tasks: 'tasks',
    default: 'default',
  },
  session: {
    root: '/session',
    default: 'default',
  },
  resources: {
    root: '/resources',
    default: 'default'
  },
  tutorials: {
    root: '/tutorials',
    default: 'default'
  },
  telehealth: {
    root: '/telehealth',
    default: 'default'
  },
  exportdata: {
    root: '/exportdata',
    default: 'default'
  }
}

export const DefaultRoute = Navigations.dashboard.root;

export default [
  {
    items: [
      {
        title: 'Dashboard',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN ],
        href: `${Navigations.dashboard.root}`,
        icon: DashboardIcon
      },
      {
        title: 'Hospitals',
        roles: [ UserRoles.SUPERUSER],
        icon: LocationCityIcon,
        href: `${Navigations.facilities.root}/${Navigations.facilities.hospitals}`,
        onClick: (e: any) => {
          e.preventDefault();
          setQueryParams({}, true);
        }
      },
      {
        title: 'Clinics',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN],
        icon: HomeWorkIcon,
        href: `${Navigations.facilities.root}/${Navigations.facilities.clinics}`,
        onClick: (e: any) => {
          e.preventDefault();
          setQueryParams({}, true);
        }
      },
      {
        title: 'Admins',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN ],
        icon: PersonPinCircleIcon,
        href: `${Navigations.users.root}/${Navigations.users.staff}`,
        onClick: (e: any) => {
          e.preventDefault();
          setQueryParams({}, true);
        }
      },
      {
        title: 'Therapists',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN ],
        icon: PeopleIcon,
        href: `${Navigations.users.root}/${Navigations.users.therapists}`,
        onClick: (e: any) => {
          e.preventDefault();
          setQueryParams({}, true);
        }
      },
      {
        title: 'Therapist',
        roles: [ UserRoles.USER ],
      },
      {
        title: 'Patients',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN, UserRoles.USER ],
        icon: FaceIcon,
        href: `${Navigations.users.root}/${Navigations.users.patients}`,
        onClick: (e: any) => {
          e.preventDefault();
          setQueryParams({}, true);
        }
      },
      {
        title: 'Tutorials',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN, UserRoles.USER, UserRoles.ENDUSER ],
        href: `${Navigations.tutorials.root}`,
        icon: HelpCenterIcon
      },
      {
        title: 'Resources',
        roles: [ UserRoles.SUPERUSER, UserRoles.SYSTEMADMIN, UserRoles.COMPANYADMIN, UserRoles.FACILITYADMIN, UserRoles.USER, UserRoles.ENDUSER ],
        href: `${Navigations.resources.root}`,
        icon: ListIcon
      }
      
    ]
  }
];
