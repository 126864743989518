import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/styles';
import {
  CircularProgress,
  Grid,
  Typography,
  Button,
  colors,
  Theme,
  Card,
  CardContent
} from '@material-ui/core';

import {
  find as _find,
  filter as _filter,
  get as _get,
  keys as _keys,
  capitalize as _capitalize,
  trimEnd as _trimEnd,
  values,
  valuesIn,
} from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import AtDatePicker from '../../../../components/form/date-picker';

import CircularIndeterminate from '../../../../components/circular-indeterminate';
import AtTextField from '../../../../components/form/text-field';
import AtAutocomplete from '../../../../components/form/autocomplete';
import { TimeZoneOptions } from '../../../../settings/app';
import { MonthSelection } from '@material-ui/pickers/views/Month/MonthView';
import session from 'redux-persist/lib/storage/session';
import { FacilityActionType } from '../../../../store/facilities/facilities-action-types';
import { UserRoles } from '../../../../settings/user-roles';
import ConfirmModal from '../../../../components/modals/confirm-modal';
import App from '../../../../app';
import { toast } from 'react-toastify';
import { Navigations } from '../../../../settings/nav-config';
//import Picker from 'react-month-picker';

const yup = require('yup');

const ExportDataFormSchema = yup.object().shape({
  clinics: yup.string(),
  sessionType: yup.string(),
  app: yup.string(),
  startdate: yup.string(),
  enddate: yup.string(),
});

const appOptions = [
  { label: "Augment Therapy", value: "1"},
  { label: "Augment Adventures", value: "2"},
  { label: "Augment Exercise", value: "3"}
];

const sessionOptions = [
  { label: "With a Provider", value: "1"},
  { label: "Solo Sessions", value: "2"},
  { label: "Telehealth Sessions", value: "3"}
];



const useStyles = makeStyles((theme: Theme) => ({
    card: {
      maxWidth: '100%',
    },
    cardContent: {
      padding: theme.spacing(2, 4),
    },
    form: {
      position: 'relative',
    },
    formHeader: {
        width: '100%',
        padding: theme.spacing(1),
        color: "#2962FE",
        fontSize: '20px'
      },
    formControl: {
      width: '100%',
      padding: theme.spacing(1),
      minHeight: 86,
      },
      helperText: {
          padding: '0 10px',
    },
    buttonContainer: {
      textAlign: 'right',
    },
    submitButton: {
      marginTop: theme.spacing(2),
      width: 100,
      padding: theme.spacing(1),
    },
    msg: {
      fontSize: "12px",
      padding: theme.spacing(2),
    }
  }));

const ExportDataForm = (props: any) => {
  const {
    type,
    loading,
    handleCancel,
    userProfile,
    clinicOptions,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [app, setApp ] = useState([]);
  const [sessionType, setSessionType] = useState([]);
  const [clinics, setClinics] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const changeClinics = (values: any) => {
    setClinics(values);
  }

  const sessionChange = (values: any) => {
    setSessionType(values);
  };

  const appChange = (values: any) => {
    setApp(values);
  };

  const hookFormMethods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    validationSchema: ExportDataFormSchema,
  });
  const { register, errors, reset, formState, getValues, setValue, watch, triggerValidation, handleSubmit } = hookFormMethods;

  const isLoadingExport = useSelector(
    (state) => _get(state, ['facilities', 'loadingDataReport']) || false,
    shallowEqual
  )

  const [ hospitalOptions, setHospitalOptions ] = useState([]);
  const allClinics = useSelector(state => _get(state, ['facilities', 'clinics']) || {data: [], total: 0}, shallowEqual);
  const activeClinic = useSelector(state => _get(state, ['facilities', 'activeClinic']) || null, shallowEqual);

  useEffect(() => {
    register('facilityUid');

    dispatch({
      type: FacilityActionType.GetFacilities,
      payload: {
        type: type,
        subType: Navigations.facilities.clinics,
        targetType: Navigations.facilities.clinics,
        facilityUid: null,
        parentFacilityUid: null,
        parentUid: null,
        skip: 0,
        take: 0,
        facilityUids: [],
      }
    });

    return () => {
    };
  }, []);

  useEffect(() => {
    const temp: any = [];

    for (const item of allClinics.data) {
      temp.push({ label: item.name, value: item.uid })
    }
    setHospitalOptions(temp);

    return () => {
    };
  }, [allClinics]);

  useEffect(() => {
    if (!activeClinic)
      return;

    setValue('facilityUid', _get(activeClinic, 'uid') || '');

    return () => {
    };
  }, [activeClinic]);

  useEffect(() => {
    if (!isLoadingExport && isLoading) {
      setIsLoading(false);
      toast.success("Your data has been exported successfully!");
    }
    return () => {}
  }, [isLoadingExport])

  const handleSave = (data: any) => {

    if (!formState.isValid)
      return;

    const formValues: any = _cloneDeep(data);
    //const formValues: any = { ...data, startDate, endDate };

    if([ UserRoles.SUPERUSER].indexOf(userProfile.role) >= 0){
      formValues.isSuperUser = true;
    }
    else
    {
      formValues.isSuperUser = false;
    }
    
    setIsLoading(true);
    dispatch({ type: FacilityActionType.GetDataExport, payload: formValues});

  }

  return (
    <>
    
    <Card className={classes.card}>
    <CardContent>
    <div className={classes.formHeader}>Export Data</div>
    {
        isLoading && (
          <CircularIndeterminate/>
        )
      }
    <form
      data-testid="export-data-form"
      className={classes.form}
      onSubmit={handleSubmit(handleSave)}>
      
      {
        isLoading && (
          <div className={classes.msg}>Please be patient while your data export is prepared...</div>
        )
      }

      

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        spacing={4}>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <AtDatePicker
                  label="Start Date"
                  value={startDate}
                  onChange={(date: Date | null) => setStartDate(date)}
                  fullWidth
                  margin="normal"
                />
                <input
                    type="hidden"
                    name="startDate"
                    value={startDate ? startDate.toISOString() : ''}
                    ref={register}
                  />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AtDatePicker
                label="End Date"
                value={endDate}
                onChange={(date: Date | null) => setEndDate(date)}
                fullWidth
                margin="normal"
              />
              <input
                    type="hidden"
                    name="endDate"
                    value={endDate ? endDate.toISOString() : ''}
                    ref={register}
                  />
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
        <AtAutocomplete
          {
            ...{
              name: 'facilityUid',
              optionLabelKey: 'label',
              optionValueKey: 'value',
              options: hospitalOptions,
              errors: errors,
              value: watch('facilityUid') || null,
              isCreatable: false,
              className: classes.formControl,
              AutocompleteProps: {
                getOptionLabel: (option: any) => {
                  return option.label
                },
                onChange: (event: object, values: any, reason: string) => {
                  setValue('facilityUid', values ? values.value : null);
                  triggerValidation('facilityUid');
                },
              },
              InputProps: {
                'data-testid': 'facilityUid',
                variant: 'outlined',
                label: 'Select a Clinic (or none for all)',
                placeholder: 'Select a Clinic (or none for all)',
              },
            }
          }
          >
          </AtAutocomplete>
          {/* <AtAutocomplete
          {
            ...{
              name: 'facilityUids',
              optionLabelKey: 'label',
              optionValueKey: 'value',
              options: hospitalOptions,
              errors: errors,
              value: watch('facilityUids') || [],
              isCreatable: false,
              className: classes.formControl,
              AutocompleteProps: {
                multiple: true,
                getOptionLabel: (option: any) => {
                  return option.label
                },
                onChange: (event: object, values: any[], reason: string) => {
                  setValue('facilityUids', values.map(value => value.value));
                },
              },
              InputProps: {
                'data-testid': 'facilityUids',
                variant: 'outlined',
                label: 'Select a Clinic (or none for all)',
                placeholder: 'Select a Clinic (or none for all)',
              },
            }
          }
          >
          </AtAutocomplete> */}
          </Grid>
        </Grid>
        <Grid
          item xs={12}>
          <div className={classes.buttonContainer}>
            <Button
              className={classes.submitButton}
              color="primary"
              size="medium"
              variant="text"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              className={classes.submitButton}
              color="secondary"
              size="medium"
              variant="contained"
              disabled={isLoading}
              type="submit"
            >
            Download
            </Button>
          </div>
        </Grid>
      </Grid>
    </form>
    </CardContent>
    </Card>
    </>
  );
}

export default ExportDataForm;
